import {
    injectFieldsToTabs,
    SkyNetTableConfig, SkyNetTableField,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    AddressCategory,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    Gateway,
} from './Gateways.types'
import {
    GatewayDataPlan,
    GatewayHardwareVersion,
    GatewayStatus,
    GatewayType,
} from './Gateway.enums'

const config = (): SkyNetTableConfig<keyof Gateway> => {
    const fields: SkyNetTableField<keyof Gateway>[] = [
        {
            name: 'gatewayType',
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayType,
            filterField: 'gatewayType',
            filterType: FilterType.Array,
            reduced: true,
            width: '148px',
        },
        {
            name: 'gatewayImeiMac',
            width: '160px',
        },
        {
            name: 'gatewayStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayStatus,
            filterField: 'gatewayStatus',
            filterType: FilterType.Array,
            width: '148px',
        },
        {
            name: 'loggerConnections',
            width: '128px',
        },
        {
            name: 'isConnectedLora',
            componentName: 'CloudIconBoolean',
            filterField: 'isConnectedLora',
            filterType: FilterType.Array,
            width: '128px',
        },
        {
            name: 'lastConnectedOn',
            componentName: 'TemperatureAge',
            width: '148px',
        },
        {
            name: 'locationLocationName',
            reduced: true,
            filterField: 'locationLocationName',
            width: '380px',
            filterType: FilterType.Array,
        },
        {
            name: 'locationCountryName',
            labelKey: 'Gateway.locationCountry',
            filterField: 'locationCountryName',
            width: '180px',
            filterType: FilterType.Array,
        },
        {
            name: 'locationPortOfReferenceIataCode',
            labelKey: 'LocationView.portOfReference',
            filterField: 'locationPortOfReferenceIataCode',
            width: '180px',
            filterType: FilterType.Array,
        },
        {
            name: 'gatewayLocationCategories',
            componentName: 'EnumList',
            allowedValuesEnum: AddressCategory,
            width: '320px',
        },
        {
            name: 'area',
            filterField: 'area',
            filterType: FilterType.Array,
            width: '160px',
        },
        {
            name: 'gatewayDeploymentName',
            filterField: 'gatewayDeploymentName',
            labelKey: 'Deployment.deploymentName',
            filterType: FilterType.Array,
            width: '220px',
        },
        {
            name: 'hardwareVersion',
            filterField: 'hardwareVersion',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayHardwareVersion,
            labelKey: 'Gateway.hardwareVersion',
            width: '180px',
        },
        {
            name: 'gatewayConnectionType',
            filterField: 'gatewayConnectionType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayDataPlan,
            width: '180px',
        },
        {
            name: 'remark',
            width: '380px',
        },
    ]

    return {
        name: 'GatewaysTable',
        tabs: injectFieldsToTabs<keyof Gateway>([
            {
                name: 'Gateways',
                url: CommonRoutes.ALL,
            },
            {
                name: 'Excluded',
                url: CommonRoutes.EXCLUDED,
            },
        ], fields),
    }
}

export default config
