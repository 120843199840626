import React, {
    useCallback, useMemo,
} from 'react'
import {
    BillingCompany,
} from 'app/Apps/ContactManagement/BillingCompanies/BillingCompanyForm.types'
import {
    BILLING_RIGHTS_WITH_CREATE_PERMISSION,
} from 'app/Apps/ContactManagement/BillingCompanies/BillingCompanies'
import useHasPermission from 'app/hooks/useHasPermission'

import useRequestTable from 'app/hooks/useRequestTable'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import request from 'app/Apps/ContactManagement/BillingCompanies/BillingCompanies.request'
import useLabel from 'app/hooks/useLabel'

import useContactManagementRoutes from 'app/Apps/ContactManagement/hooks/useContactManagementRoutes'
import RichTableWithAddIcon from 'app/shared-components/RichTableWithAddIcon'
import getColumns from './BillingCompaniesByAccountCompany.columns'

export default function BillingCompaniesByAccountCompany({
    id,
}: Readonly<{id: number}>) {
    const l = useLabel()
    const {
        BillingCompanies: BillingCompaniesRoutes,
    } = useContactManagementRoutes()
    const hasBillingCreate = useHasPermission(BILLING_RIGHTS_WITH_CREATE_PERMISSION)

    const {
        onParamsChange,
        loadData,
    } = useRequestTable<{data: BillingCompany[]}>({
        key: RequestKeys.getBillingCompanies,
        params: {},
        defaultValue: {
            filter: {
                companyId: [id],
            },
        },
        config: request.filterRequest,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    const openCreateForm = useCallback(() => {
        BillingCompaniesRoutes.Create.navigate({
            options: {
                state: {
                    company: {
                        id,
                    },
                },
            },
        })
    }, [
        BillingCompaniesRoutes.Create,
        id,
    ])

    const onRowClick = useCallback(({
        id: companyId,
    }: BillingCompany) => {
        BillingCompaniesRoutes.Overview.navigate({
            params: {
                key: companyId,
            },
        })
    }, [BillingCompaniesRoutes.Overview])

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    return (
        <RichTableWithAddIcon
            title="Billing Companies"
            onOpenForm={openCreateForm}
            name={request.domainName}
            configName="BillingCompanyByAccountTable"
            createEnabled={hasBillingCreate}
            loadData={loadData}
            onParamsChange={onParamsChange}
            columns={columns}
            onRowClick={onRowClick}
        />
    )
}
