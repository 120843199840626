/* eslint-disable react/jsx-props-no-spreading */

import React, {
    useCallback,
    useMemo,
} from 'react'
import ReactApexChart from 'react-apexcharts'

import useLabel from 'app/hooks/useLabel'
import {
    renderToString,
} from 'react-dom/server'
import {
    Availability, ContainerAvailabilityForecastContainerStatuses,
} from '../../../serviceCenters.types'
import getOptions from './optionsChart'
import CustomTooltip from './CustomTooltip'

const ContainerAvailabilityForecastChart = ({
    value,
    listStatuses,
    containerStatus,
    minPreconditioned,
}: {
    value: Availability[],
    listStatuses: string[],
    containerStatus: Record<string, boolean>,
    minPreconditioned: number,
}) => {
    const l = useLabel()

    const categories: string[] = useMemo(() => {
        return value?.map((item) => {
            return item?.date
        })
    }, [value])

    const customTooltip = useCallback(({
        series: selectedSeries,
        dataPointIndex,
        w,
    }: {
        series: number[][],
        dataPointIndex: number,
        w: {
            globals :{
                labels: string[],
            }
        }
    }) => {
        // to map array selectedSeries (can have different length)
        // to object containerStatus to show proper values in tooltip
        let index = 0

        const seriesBySelectedStatuses: Record<
            ContainerAvailabilityForecastContainerStatuses, number[]
        > = Object.keys(containerStatus)
            .reduce((acc, status) => {
                if (containerStatus[status]) {
                    const res = {
                        ...acc,
                        [status]: selectedSeries[index],
                    }

                    index += 1

                    return res
                }
                return {
                    ...acc,
                    [status]: null,
                }
            }, {} as Record<ContainerAvailabilityForecastContainerStatuses, number[]>)

        return renderToString(
            <CustomTooltip
                date={w.globals.labels[dataPointIndex]}
                minPreconditioned={minPreconditioned}
                statuses={Object.entries(seriesBySelectedStatuses).reduce((acc, [
                    key,
                    v,
                ]) => {
                    return {
                        ...acc,
                        [key]: v?.[dataPointIndex],
                    }
                }, {} as Record<ContainerAvailabilityForecastContainerStatuses, number>)}
            />,
        )
    }, [
        containerStatus,
        minPreconditioned,
    ])

    const series = useMemo(() => {
        return value
            ?.reduce(
                (acc: {name: string, data: number[]}[], availability) => {
                    if (!acc.length) {
                        return listStatuses
                            .filter((statusName) => {
                                return containerStatus[statusName]
                            })
                            .map((statusName) => {
                                return {
                                    name: statusName,
                                    data: [availability[statusName]],
                                }
                            })
                    }

                    return acc.map((el) => {
                        return {
                            ...el,
                            data: [
                                ...el.data,
                                availability[el.name],
                            ],
                        }
                    })
                }, [],
            )
            .map((item) => {
                return {
                    name: l(`Monitoring.${[item.name]}`),
                    data: item.data,
                }
            })
    }, [
        containerStatus,
        l,
        listStatuses,
        value,
    ])

    const options = useMemo(() => {
        return getOptions({
            categories,
            minPreconditioned,
            customTooltip,
            containerStatus,
        })
    }, [
        categories,
        containerStatus,
        customTooltip,
        minPreconditioned,
    ])

    return (
        <ReactApexChart
            options={options}
            height={700}
            style={{
                width: '100%',
            }}
            series={series || []}
            type="bar"
        />
    )
}

export default ContainerAvailabilityForecastChart
