import React, {
    useMemo, useState,
} from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'

import useGetLongTermLease from '../hooks/useGetLongTermLease'
import getFields from './requiredContainers.fields'
import ModifyRequiredContainersDialog from './ModifyRequiredContainersDialog'
import AvailableContainers from './AvailableContainers'
import DedicatedContainers from './DedicatedContainers'

const Containers = ({
    id,
}: {
    id: number
}) => {
    const [
        openModificationDialog,
        setModificationDialogOpen,
    ] = useState<boolean>(false)

    const getAllowedValues = useEnumValues()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLongTermLease(id)

    const fields = useMemo(() => {
        return getFields({
            getAllowedValues,
        })
    }, [getAllowedValues])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <DomainObjectForm
                    name="LongTermLeaseRequiredContainers"
                    value={data}
                    fields={fields}
                    exists
                    disabled
                />
                <ButtonContainer>
                    <Button
                        label="modify required containers"
                        onClick={() => { setModificationDialogOpen(true) }}
                        data-testid="btn-modify-required-containers"
                    />
                </ButtonContainer>
                <AvailableContainers
                    id={id}
                    onSuccess={invalidate}
                    tempRange={data?.tempRange}
                />
                <DedicatedContainers
                    id={id}
                />
                <ModifyRequiredContainersDialog
                    open={openModificationDialog}
                    setOpen={setModificationDialogOpen}
                    onSuccess={invalidate}
                    longTermLeaseId={id}
                    requiredContainers={data?.requiredContainers}
                    tempRange={data?.tempRange}
                />
            </>
        </StatusHandler>
    )
}

export default Containers
