import React, {
    useMemo,
    useState,
    useCallback,
    useEffect,
} from 'react'
import {
    Grid,
} from '@mui/material'
import pick from 'lodash/pick'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'

import StatusHandler from 'app/shared-components/StatusHandler'
import FormWithControls from 'app/shared-components/FormWithControls'

import {
    ContainerStatus,
} from 'app/types/enums'
import changeStatusFields from './ChangeStatus.fields'
import useStyles from './ChangeStatus.styles'
import containerRequests from '../../Containers.request'
import useGetContainerBySerialNumber from '../../hooks/useGetContainerBySerialNumber'
import useGetAllowedContainerStatusTransitions from './hooks/useGetAllowedContainerStatusTransitions'

type ContainerChangeStatusProps = {
    id: string,
}

export default function ChangeStatus({
    id: serialNumber,
}: Readonly<ContainerChangeStatusProps>) {
    const {
        classes,
    } = useStyles()

    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetContainerBySerialNumber(serialNumber)

    const statusTransitions = useGetAllowedContainerStatusTransitions(data?.containerStatus)

    const getAllowedValues = useEnumValues()
    const [
        isToBeLoggerExchanged,
        setIsToBeLoggerExchanged,
    ] = useState(false)

    const onChange = useCallback(({
        containerStatus: newContainerStatus,
    }) => {
        setIsToBeLoggerExchanged(newContainerStatus === ContainerStatus.TO_BE_LOGGER_EXCHANGED)
    }, [])

    useEffect(() => {
        if (isDataReady) {
            setIsToBeLoggerExchanged(
                data?.containerStatus === ContainerStatus.TO_BE_LOGGER_EXCHANGED,
            )
        }
    }, [
        data?.containerStatus,
        isDataReady,
    ])

    const fieldsWithClasses = useFieldsWithClassName(
        changeStatusFields({
            isToBeLoggerExchanged, getAllowedValues, statusTransitions,
        }),
        classes,
    )

    const value = useMemo(() => {
        return pick(data, changeStatusFields({
            isToBeLoggerExchanged: data?.containerStatus, getAllowedValues, statusTransitions,
        })[0].fields.map(({
            name,
        }) => {
            return name
        }))
    }, [
        statusTransitions,
        data,
        getAllowedValues,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                { data?.id ? (
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <FormWithControls
                                name="ContainerStatusChangeDto"
                                value={value}
                                fields={fieldsWithClasses}
                                onSuccess={invalidate}
                                classNames={{
                                    gridWrapper: classes.gridWrapper,
                                    gridCardWrapper: classes.gridCardWrapper,
                                }}
                                setExternalValue={onChange}
                                exists
                                requestParams={containerRequests.statusChange({
                                    serialNumber,
                                })}
                            />
                        </Grid>
                    </Grid>
                ) : null }
            </>
        </StatusHandler>
    )
}
