import {
    FieldInCard,
} from 'app/types/form.types'
import {
    LoggerType,
} from 'app/types/enums'

import {
    Container,
} from '../../containers.types'

type ContainerChangeStatus = Pick<Container, 'containerStatus' | 'requiredLoggerExchange'> & {
    containerManualStatusChangeTimestamp: string,
    containerStatusChangeDocumentation: (string | number)[],
    containerManualStatusChangeComment: string,
}

const changeStatusFields = ({
    isToBeLoggerExchanged,
    getAllowedValues,
    statusTransitions,
}): FieldInCard<keyof ContainerChangeStatus>[] => {
    const defaultFields: FieldInCard<keyof ContainerChangeStatus> = {
        title: 'Container Status',
        fields: [
            {
                name: 'containerStatus',
                componentName: 'EnumSelector',
                allowedValues: statusTransitions,
                labelKey: 'ContainerStatusChangeDto.containerStatus',
                showOutOfRangeOption: true,
            },
            {
                name: 'containerManualStatusChangeComment',
                componentName: 'LongText',
                className: 'comment',
                labelKey: 'ContainerStatusChangeDto.containerManualStatusChangeComment',
            },
            {
                name: 'containerStatusChangeDocumentation',
                labelKey: 'ContainerStatusChangeDto.containerStatusChangeDocumentation',
                className: 'documentation',
                componentName: 'AttachmentsObjectSelector',
            },
            {
                name: 'containerManualStatusChangeTimestamp',
                componentName: 'DateTimeZonedSelect',
                labelKey: 'ContainerStatusChangeDto.containerManualStatusChangeTimestamp',
            },
        ],
    }

    return isToBeLoggerExchanged ? [{
        title: defaultFields.title,
        fields: [
            ...defaultFields.fields,
            {
                name: 'requiredLoggerExchange',
                componentName: 'MultiEnumSelector',
                allowedValues: getAllowedValues(LoggerType),
                required: true,
            },
        ],
    }] : [defaultFields]
}

export default changeStatusFields
