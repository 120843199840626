import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import simpleApi from 'app/services/simpleApi'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from '../longTermLease.request'
import {
    ModificationRequiredContainersType,
} from '../longTermLease.types'

const useModifyRequiredContainers = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessModify,
        onError,
    } = useHandleStatusRequest({
        id: 'ModifyRequiredContainers',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            data,
            longTermLeaseId,
        }: {
            data: Partial<ModificationRequiredContainersType>,
            longTermLeaseId: number
        }) => {
            return simpleApi({
                ...requests.ModifyRequiredContainers({
                    data,
                    longTermLeaseId,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessModify,
    })
}

export default useModifyRequiredContainers
