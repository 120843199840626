import {
    useMemo,
} from 'react'

import {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'

export type TabConfig = {
    tab: SidebarTab,
    permission: boolean
}

export default (tabs: TabConfig[] = []) => {
    return useMemo((): SidebarTab[] => {
        return tabs.reduce((tabAcc, {
            tab, permission,
        }) => {
            if (permission) {
                return [
                    ...tabAcc,
                    tab,
                ]
            }

            return tabAcc
        }, [])
    }, [tabs])
}
