import React from 'react'

import CommentsTable from '../../CommentsTable'
import useGetLongTermLeaseComments from './hooks/useGetLongTermLeaseComments'
import useRemoveTermLeaseComment from './hooks/useRemoveTermLeaseComment'
import requestsConfig from './longTermLeaseComments.request'

const LongTermLeaseComments = ({
    longTermLeaseId,
}: {
    longTermLeaseId: number,
}) => {
    const {
        onParamsChange,
        loadData,
        refetch,
    } = useGetLongTermLeaseComments(longTermLeaseId)

    const deleteComment = useRemoveTermLeaseComment({
        onSuccess: refetch,
    })

    return (
        <CommentsTable
            requestConfigCreate={requestsConfig.CreateLongTermLeaseComment({
                longTermLeaseId,
            })}
            deleteComment={deleteComment}
            onParamsChange={onParamsChange}
            loadData={loadData}
            refetch={refetch}
        />
    )
}

export default LongTermLeaseComments
