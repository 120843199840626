import React, {
    useCallback, useMemo,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'

import useHasPermission from 'app/hooks/useHasPermission'
import request from 'app/Apps/RelationshipsManagement/ActivityRecords/ActivityRecords.requests'
import useLabel from 'app/hooks/useLabel'
import useRelationshipsManagementRoutes from 'app/Apps/RelationshipsManagement/hooks/useRelationshipsManagementRoutes'
import {
    ActivityRecordTable,
} from 'app/Apps/RelationshipsManagement/ActivityRecords/activityRecords.types'
import RichTableWithAddIcon from 'app/shared-components/RichTableWithAddIcon'
import getColumns from './ActivityRecordsByAccountCompany.columns'

export default function ActivityRecordsByAccountCompany({
    id,
}: Readonly<{id: number}>) {
    const l = useLabel()
    const {
        ActivityRecords: ActivityRecordsRoutes,
    } = useRelationshipsManagementRoutes()

    const hasCreatePermissions = useHasPermission([
        'relationships_ops',
        'relationships_sales',
    ])

    const {
        onParamsChange,
        loadData,
    } = useRequestTable<{data: ActivityRecordTable[]}>({
        key: RequestKeys.getActivityRecords,
        params: {},
        defaultValue: {
            data: {
                filters: {
                    companyId: id,
                },
            },
        },
        config: request.filterRequest,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    const openCreateForm = useCallback(() => {
        ActivityRecordsRoutes.Create.navigate({
            options: {
                state: {
                    companies: [{
                        id,
                    }],
                },
            },
        })
    }, [
        ActivityRecordsRoutes.Create,
        id,
    ])

    const onRowClick = useCallback(({
        id: recordId,
    }: ActivityRecordTable) => {
        ActivityRecordsRoutes.Overview.navigate({
            params: {
                key: recordId,
            },
        })
    }, [ActivityRecordsRoutes.Overview])

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    return (
        <RichTableWithAddIcon
            title="Activity Records"
            onOpenForm={openCreateForm}
            name={request.domainName}
            configName="ActivityRecordsByAccountTable"
            createEnabled={hasCreatePermissions}
            loadData={loadData}
            onParamsChange={onParamsChange}
            columns={columns}
            onRowClick={onRowClick}
        />
    )
}
