import {
    RequestConfig,
} from 'app/types/request.types'

type ActivityRecordsRequests = {
    update: (
        id: number,
    ) => RequestConfig,
    archive: ({
        id,
    }: {id: number}) => RequestConfig,
    restore: ({
        id,
    }: {id: number}) => RequestConfig,
    filterRequest: (p?: {params?: Record<string, string>}) => RequestConfig,
    domainName: string,
}

const request: ActivityRecordsRequests = {
    domainName: 'activityrecord',
    filterRequest: ({
        params = {},
    } = {}) => {
        return {
            url: 'activityrecord/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    update: (
        id,
    ) => {
        return {
            url: `activityrecord/${id}`,
            method: 'PUT',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `activityrecord/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `activityrecord/${id}/restore`,
            method: 'PATCH',
        }
    },
}

export default request
