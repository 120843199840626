import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    LaneStatus, LeaseType,
} from 'app/types/enums'

import {
    AccountLanesTable,
} from '../../AccountLanes/AccountLanes.types'

export default function getLanesByAccountColumns({
    l,
}: {l: (s: string) => string}): RichTableColumns<keyof AccountLanesTable>[] {
    return [
        {
            id: 'laneNumber',
            headerProps: {
                children: l('AccountLane.laneNumber'),
            },
            mapCellProps: 'laneNumber',
        },
        {
            id: 'laneStatus',
            headerProps: {
                children: l('LaneQuoteRequest.selectedLaneStatus'),
            },
            mapCellProps: 'laneStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: LaneStatus,
        },
        {
            id: 'originAirport',
            headerProps: {
                children: l('AccountLane.originAirport'),
            },
            mapCellProps: 'originAirport',
        },
        {
            id: 'destinationAirport',
            headerProps: {
                children: l('AccountLane.destinationAirport'),
            },
            mapCellProps: 'destinationAirport',
        },
        {
            id: 'opportunity',
            headerProps: {
                children: l('AccountLane.opportunity'),
            },
            mapCellProps: 'opportunity',
        },
        {
            id: 'leaseType',
            headerProps: {
                children: l('AccountLane.leaseType'),
                sorting: false,
            },
            mapCellProps: 'leaseType',
            componentName: 'EnumValue',
            allowedValuesEnum: LeaseType,
        },
        {
            id: 'customerExecution',
            headerProps: {
                children: l('AccountLane.customerExecution'),
            },
            mapCellProps: 'customerExecution',
        },
        {
            id: 'opportunityCollaborators',
            headerProps: {
                children: l('Opportunity.opportunityCollaborators'),
            },
            mapCellProps: 'opportunityCollaborators',
            componentName: 'List',
        },
    ]
}
