import {
    useMemo,
    useCallback,
} from 'react'
import flow from 'lodash/flow'
import get from 'lodash/get'

import useLabel from 'app/hooks/useLabel'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'

import {
    SkyNetTableConfig, SkyNetTableField, SkyNetTableTabWithLabel,
} from '../types/skyNetTable.types'
import getBaseRequestUrl from './utils/getBaseRequestUrl'
import replaceLabels from './utils/replaceLabels'
import createColumnObj from './utils/createColumnObj'
import useTableTab from './useTableTab'

type Params = {
    customUrl?: string,
    name: string,
    moduleName?: string,
    tableConfig: SkyNetTableConfig,
    selectedTabUrlQuery?: string,
    tableTabs?: SkyNetTableTabWithLabel[],
}
const usePrepareColumns = ({
    customUrl,
    name,
    moduleName,
    tableConfig,
    selectedTabUrlQuery,
    tableTabs = [],
}: Params): SkyNetTableField[] => {
    const l = useLabel()
    const replaceLabelsForColumns = useCallback(
        (columns) => {
            return replaceLabels({
                name, l, labelEntityName: tableConfig.labelEntityName,
            })(columns)
        },
        [
            name,
            l,
            tableConfig.labelEntityName,
        ],
    )

    const dataEntityName = moduleName || name

    const selectedTabCustomUrl = useMemo((): string => {
        return customUrl || `${getBaseRequestUrl(dataEntityName, customUrl)}/${selectedTabUrlQuery}`
    }, [
        customUrl,
        dataEntityName,
        selectedTabUrlQuery,
    ])

    const selected = useTableTab({
        tabs: tableTabs,
    })

    const injectLoadFilters = useLoadFilters({
        customUrl: selectedTabCustomUrl,
        preDefinedFilters: selected?.filters,
    })

    const updater = useMemo(() => {
        return flow([
            createColumnObj,
            replaceLabelsForColumns,
            injectLoadFilters,
        ])
    }, [
        replaceLabelsForColumns,
        injectLoadFilters,
    ])

    const fields = useMemo((): {}[] => {
        const tab = selected || ('tabs' in tableConfig && tableConfig.tabs.at(0))

        return get(tab || tableConfig, 'fields', [])
    }, [
        tableConfig,
        selected,
    ])

    return useMemo((): {}[] => {
        return updater(fields)
    }, [
        updater,
        fields,
    ])
}

export default usePrepareColumns
