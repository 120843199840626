import React, {
    useCallback, useMemo,
} from 'react'
import {
    useGetOpportunitiesByAccount,
} from 'app/Apps/AccountManagement/Opportunities'
import useLabel from 'app/hooks/useLabel'

import useHasPermission from 'app/hooks/useHasPermission'

import RichTableWithAddIcon from 'app/shared-components/RichTableWithAddIcon'
import request from '../../Opportunities/opportunities.request'

import getColumns from './OpportunitiesByAccount.columns'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'
import {
    OpportunityTableType,
} from '../../Opportunities/opportunities.types'

export default function OpportunitiesByAccount({
    id,
}: Readonly<{id: number}>) {
    const l = useLabel()
    const {
        Opportunities: OpportunitiesRoutes,
    } = useAccountManagementRoutes()
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])

    const {
        onParamsChange,
        loadData,
    } = useGetOpportunitiesByAccount(id)

    const openCreateForm = useCallback(() => {
        OpportunitiesRoutes.Create.navigate({
            options: {
                state: {
                    autofill: {
                        account: {
                            id,
                        },
                    },
                },
            },
        })
    }, [
        OpportunitiesRoutes.Create,
        id,
    ])

    const onRowClick = useCallback(({
        id: opportunityId,
    }: OpportunityTableType) => {
        OpportunitiesRoutes.Overview.navigate({
            params: {
                key: opportunityId,
            },
        })
    }, [OpportunitiesRoutes.Overview])

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    return (
        <RichTableWithAddIcon
            title="Opportunities"
            onOpenForm={openCreateForm}
            name={request.domainName}
            configName="OpportunitiesByAccountTable"
            createEnabled={accountCreate}
            loadData={loadData}
            onParamsChange={onParamsChange}
            columns={columns}
            onRowClick={onRowClick}
        />
    )
}
