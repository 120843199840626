import moment from 'moment'
import {
    ContainerAvailabilityForecastContainerStatuses,
} from '../../../serviceCenters.types'

const getOptions = ({
    categories,
    minPreconditioned,
    customTooltip,
    containerStatus,
}: {
    categories: string[],
    minPreconditioned: number,
    customTooltip: (args: any) => string,
    containerStatus: Record<string, boolean>,
}) => {
    const colorSet = {
        [ContainerAvailabilityForecastContainerStatuses.ToBeProcessed]: ({
            value,
        }) => {
            return value < 0 ? '#D44848' : '#F2CA8B'
        },
        [ContainerAvailabilityForecastContainerStatuses.InProcess]: ({
            value,
        }) => {
            return value < 0 ? '#D44848' : '#EDAE49'
        },
        [ContainerAvailabilityForecastContainerStatuses.Processed]: ({
            value,
        }) => {
            return value < 0 ? '#D44848' : '#8BD1E8'
        },
        [ContainerAvailabilityForecastContainerStatuses.InPreconditioning]: ({
            value,
        }) => {
            return value < 0 ? '#D44848' : '#57B1CF'
        },
        [ContainerAvailabilityForecastContainerStatuses.Preconditioned]: ({
            value,
        }) => {
            return value < minPreconditioned ? '#D44848' : '#4790A8'
        },
    }

    const seriesColors = Object.entries(containerStatus).reduce((acc, [
        status,
        active,
    ]) => {
        if (active) {
            acc.push(colorSet[status])
        }

        return acc
    }, [])

    return {
        legend: {
            horizontalAlign: 'left',
        } as ApexLegend,
        tooltip: {
            custom: customTooltip,
        },
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: true,
                offsetX: -60,
                offsetY: 0,
                tools: {
                    download: '<img src="/assets/images/download_button.svg" width="24px" height="24px" alt="download"/>',
                },
            },
        },
        xaxis: {
            categories,
            labels: {
                formatter(value) {
                    return moment(value, 'DD.MM.YYYY').format('DD.MM')
                },
            },
        },
        colors: seriesColors,
        dataLabels: {
            enabled: false,
        },
        grid: {
            padding: {
                right: 70,
            },
            xaxis: {
                lines: {
                    show: true,
                },
            },
            strokeDashArray: 7,
        },
        annotations: {
            yaxis: [{
                y: minPreconditioned,
                strokeDashArray: 10,
                borderColor: '#00739A',
                label: {
                    style: {
                        fontSize: '15px',
                        color: '#00739A',
                        background: 'none',
                    },
                    offsetX: 70,
                    textAnchor: 'end',
                    borderWidth: 0,
                    text: 'Min Precon',
                },
            }],
        },
    }
}

export default getOptions
