import useRequest, {
    RequestFn, RequestKeys,
} from 'app/hooks/useRequest'

import requestConfig from '../longTermLease.request'
import {
    DedicatedContainerType,
} from '../longTermLease.types'

const useGetDedicatedContainers = (longTermLeaseId: number) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<DedicatedContainerType[]>({
        key: RequestKeys.getDedicatedContainers,
        params: {
            longTermLeaseId,
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetDedicatedContainers, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
        enabled: Boolean(longTermLeaseId),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetDedicatedContainers
