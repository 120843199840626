import React, {
    useMemo,
} from 'react'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import useEnumValues from 'app/hooks/useEnumValues'
import StatusHandler from 'app/shared-components/StatusHandler'

import useGetLongTermLease from '../hooks/useGetLongTermLease'
import getFields from './longTermLeaseOverview.fields'
import LongTermLeaseComments from '../LongTermLeaseComments'
import useUpdateStatus from '../hooks/useUpdateStatus'
import {
    StatusLongTermLease,
} from '../longTermLease.types'
import useOrderManagementRoutes from '../../hooks/useOrderManagementRoutes'
import useStyles from './LongTermLeaseOverview.styles'

const LongTermLeaseOverview = ({
    id,
}: {
    id: number
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        LongTermLease: LongTermLeaseRoutes,
    } = useOrderManagementRoutes()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLongTermLease(id)

    const {
        mutate,
    } = useUpdateStatus(invalidate)

    const allowedProductTypes = useMemo(() => {
        return data?.requiredContainers?.map(({
            productType,
        }) => {
            return productType
        })
    }, [data?.requiredContainers])

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        allowedProductTypes,
        status: data?.status,
    }), classes)

    const onChangeStatus = (action: string) => {
        mutate({
            id,
            action,
        })
    }

    const moveToContainersTab = () => {
        LongTermLeaseRoutes.Containers.navigate({
            params: {
                key: id,
            },
        })
    }

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <DomainObjectForm
                    value={data}
                    className={classes.gridWrapper}
                    fields={fields}
                    disabled
                    name="LongTermLease"
                />
                <ButtonContainer>
                    {data?.status === StatusLongTermLease.NOT_STARTED && (
                        <Button
                            label="confirm"
                            onClick={() => { onChangeStatus('confirmed') }}
                            data-testid="confirm-button"
                        />
                    )}
                    {data?.status === StatusLongTermLease.CONFIRMED && (
                        <Button
                            label="add containers"
                            onClick={moveToContainersTab}
                        />
                    )}
                    {data?.status === StatusLongTermLease.BOOKED && (
                        <Button
                            label="start rental"
                            onClick={() => { onChangeStatus('rental') }}
                            data-testid="rental-button"
                        />
                    )}
                    {data?.status === StatusLongTermLease.RENTAL && (
                        <Button
                            label="close contract"
                            onClick={() => { onChangeStatus('closed') }}
                            data-testid="closed-button"
                        />
                    )}
                </ButtonContainer>
                <LongTermLeaseComments longTermLeaseId={id} />
            </>
        </StatusHandler>
    )
}

export default LongTermLeaseOverview
