import React, {
    useCallback,
} from 'react'
import clsx from 'clsx'
import {
    FormControlLabel,
    RadioGroup,
    Radio as MaterialRadio,
} from '@mui/material'

import Label from 'app/shared-components/Label'

import useStyles from './Radio.styles'

const defaultProps = {
    disabled: false,
    title: '',
    options: [],
    value: '',
    name: 'labelType',
    compact: false,
    small: false,
    className: '',
    showInColumns: false,
    required: false,
}

const Radio = ({
    onChange,
    disabled,
    title,
    options,
    name,
    value,
    compact,
    small,
    className,
    showInColumns,
    required,
}: {
    onChange: (...a: any[]) => void,
    disabled?: boolean,
    title?: string,
    options?: {
        value: string | boolean,
        label: string,
    }[],
    name?: string,
    value?: string | boolean,
    compact?: boolean,
    small?: boolean,
    className?: string,
    showInColumns?: boolean,
    required?: boolean,
}) => {
    const {
        classes,
    } = useStyles()

    const handleOnChange = useCallback((event) => {
        onChange(event.target.value, event)
    }, [onChange])

    return (
        <>
            {title && (<Label title={title} />)}
            <div className={clsx(className,
                {
                    [classes.isRequired]: required,
                })}
            >
                <div className={classes.requiredMark} />
                <RadioGroup
                    aria-label="labelType"
                    name={name}
                    value={value}
                    className={clsx({
                        [classes.radio]: true,
                        [className]: true,
                        [classes.showInColumns]: showInColumns,
                    })}
                    onChange={handleOnChange}
                    data-testid={`radio-${name}`}
                >
                    {options.map((radio) => {
                        return (
                            <FormControlLabel
                                key={`radio-${radio.value}`}
                                value={radio.value}
                                className={clsx({
                                    [classes.formControlStyle]: true,
                                    [classes.radioSmall]: small,
                                })}
                                classes={{
                                    label: classes.radioItemLabel,
                                }}
                                control={(
                                    <MaterialRadio
                                        color="primary"
                                        classes={{
                                            root: compact && classes.radioRoot,
                                        }}
                                        disabled={disabled}
                                    />
                                )}
                                data-value={radio.value}
                                label={radio.label}
                                disabled={disabled}
                            />
                        )
                    })}
                </RadioGroup>
            </div>
        </>
    )
}

Radio.defaultProps = defaultProps

export default Radio
