/* eslint-disable react/no-array-index-key */
import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'
import Label from 'app/shared-components/Label'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
// We have to use this one for now as Grid are deprecated in 6v
import Grid from '@mui/material/Unstable_Grid2'

import useStyles from './AdvancedList.styles'

export type ListColumn = {
    /* The number of columns the label should span */
    /* between 1 and 10 */
    colSpan?: number | true,
    label: string,
    align?: 'right',
}

type Props<T = object> = {
    onChange: (e) => void,
    disabled?: boolean,
    value?: T[],
    defaultValue?: Partial<T>,
    renderItem: ({
        index,
        item,
        handleChange,
        state,
    }:{
        index: number,
        item: T,
        handleChange: (item: T, i: number) => void,
        state: T[],
    }) => JSX.Element,
    addButtonLabel?: string,
    columns: ListColumn[],
    validateForAdd?: (value: T) => boolean,
}

const defaultProps: Partial<Props> = {
    value: undefined,
    disabled: false,
    addButtonLabel: undefined,
    validateForAdd: undefined,
    defaultValue: {},
}

const DEFAULT_NUMBER_OF_COLUMNS = 10
const DEFAULF_COLUMN_SPACING = 2

function AdvancedList<T = object>({
    onChange,
    disabled,
    value,
    defaultValue,
    renderItem,
    addButtonLabel,
    columns,
    validateForAdd,
}: Readonly<Props<T>>) {
    const {
        classes,
        cx,
    } = useStyles()

    const filterValues = useCallback((values: T[]) => {
        return onChange(values.filter((el) => {
            return Object.values(el).every((i) => { return Boolean(i) })
        }))
    }, [onChange])

    const [
        state,
        setState,
    ] = useState<T[]>(() => {
        return value || [defaultValue as T]
    })

    const addNewItem = useCallback(() => {
        setState([
            ...state,
            (defaultValue as T),
        ])
    }, [
        state,
        defaultValue,
    ])

    const deleteItem = useCallback((i: number) => {
        setState((prev) => {
            const next = [...prev]

            next.splice(i, 1)

            filterValues(next)
            return next
        })
    }, [filterValues])

    const handleChange = useCallback((item: T, i: number) => {
        setState((prev) => {
            const next = [...prev]

            next.splice(i, 1, item)
            filterValues(next)
            return next
        })
    }, [filterValues])

    const showRemove = state.length > 1
    const label = `Add ${addButtonLabel || ''}`
    const numberColumns = (!disabled && showRemove)
        ? (DEFAULT_NUMBER_OF_COLUMNS + 1) : DEFAULT_NUMBER_OF_COLUMNS
    const canAddNewItem = useMemo(() => {
        if (validateForAdd) {
            return state.every((item) => {
                return validateForAdd(item)
            })
        }
        return true
    }, [
        state,
        validateForAdd,
    ])

    return (
        <div className={classes.root}>
            <Grid
                key="my-grid"
                container
                spacing={DEFAULF_COLUMN_SPACING}
                columnSpacing={disabled ? 0 : DEFAULF_COLUMN_SPACING}
                columns={numberColumns}
                className={classes.gridContainer}
                sx={() => {
                    return {
                        '--Grid-borderWidth': '1px',
                        '& > div': {
                            borderBottom: 'var(--Grid-borderWidth) solid',
                            borderColor: '#0000001F',
                        },
                    }
                }}
            >
                {columns.map(({
                    colSpan,
                    label: columnLabel,
                    align,
                }) => {
                    return (
                        <Grid
                            xs={colSpan}
                            className={classes.header}
                            key={`header-${columnLabel}`}
                        >
                            <Label
                                title={columnLabel}
                                className={cx(classes.label, align === 'right' && classes.rightAlign)}
                            />
                        </Grid>
                    )
                })}
                {!disabled && showRemove && (
                    <Grid
                        key="filler"
                        xs="auto"
                        pl={0}
                        pr={0}
                    >
                        <div className={classes.removeHeaderFiller} />
                    </Grid>
                )}
                {state.map((item, index) => {
                    return (
                        <React.Fragment
                            key={`row-${index}`}
                        >
                            {renderItem({
                                index,
                                item,
                                handleChange,
                                state,
                            })}
                            {!disabled && showRemove && (
                                <Grid
                                    key={`delete-icon-${index}`}
                                    xs="auto"
                                    pl={0}
                                    pr={0}
                                >
                                    <DeleteIcon
                                        color="action"
                                        data-testid="DeleteIcon"
                                        onClick={() => {
                                            deleteItem(index)
                                        }}
                                    />
                                </Grid>
                            )}
                        </React.Fragment>
                    )
                })}
            </Grid>
            {!disabled && (
                <div className={classes.btnContainer}>
                    <button
                        disabled={!canAddNewItem}
                        data-testid="add-new-button"
                        type="button"
                        onClick={addNewItem}
                        className={cx(classes.btn, !canAddNewItem && classes.btnDisabled)}
                    >
                        <AddIcon
                            className={classes.addIcon}
                            fontSize="small"
                        />
                        {label}
                    </button>
                </div>
            )}
        </div>
    )
}

AdvancedList.defaultProps = defaultProps

export default AdvancedList
