import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetDedicatedContainers from '../../hooks/useGetDedicatedContainers'
import DedicatedContainers from './DedicatedContainers'

const DedicatedContainersContainer = ({
    id,
}: {
    id: number,
}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetDedicatedContainers(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <DedicatedContainers
                value={data}
                onSuccess={invalidate}
                longTermLeaseId={id}
            />
        </StatusHandler>
    )
}

export default DedicatedContainersContainer
