import React, {
    useMemo,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import AccountOverview from './AccountOverview'
import AccountUpdate from './AccountUpdate'
import getTableConfig from './Accounts.config'
import CreateAccount from './AccountCreate'
import RegionalGroups from './RegionalGroups'
import Contacts from './Contacts'
import {
    AccountTabs,
} from './Account.types'
import OpportunitiesByAccount from './OpportunitiesByAccount'
import LanesByAccount from './LanesByAccount'
import QuotesByAccount from './QuotesByAccount'
import {
    BillingCompaniesByAccount,
} from './BillingCompaniesByAccountCompany'
import {
    AddressesByAccount,
} from './AddressesByAccountCompany'
import {
    ActivityRecordsByAccount,
} from './ActivityRecordsByAccountCompany'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Accounts = () => {
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: CommonRoutes.OVERVIEW,
                Component: AccountOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: CommonRoutes.DATA_UPDATE,
                Component: AccountUpdate,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Regional Groups',
                url: AccountTabs.RegionalGroups,
                Component: RegionalGroups,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Opportunities',
                url: AccountTabs.Opportunities,
                Component: OpportunitiesByAccount,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Lanes',
                url: AccountTabs.Lanes,
                Component: LanesByAccount,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Quotes',
                url: AccountTabs.Quotes,
                Component: QuotesByAccount,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Billing Companies',
                url: AccountTabs.BillingCompanies,
                Component: BillingCompaniesByAccount,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Addresses',
                url: AccountTabs.Addresses,
                Component: AddressesByAccount,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Contacts',
                url: AccountTabs.Contacts,
                Component: Contacts,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Activity Records',
                url: AccountTabs.ActivityRecords,
                Component: ActivityRecordsByAccount,
            },
            permission: accountUpdate,
        },
    ])

    const actionComponents = useMemo(() => {
        return {
            Create: accountCreate ? CreateAccount : undefined,
        }
    }, [accountCreate])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={actionComponents}
            name={tableConfig.name}
            defaultTab="overview"
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default Accounts
