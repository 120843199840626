import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from '../longTermLease.request'

const useBookContainers = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessLongTermLeaseUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Added to dedicated containers',
        id: 'AddToDedicatedContainers',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            id, data,
        }:{id: number, data: {id: number}[]}) => {
            return simpleApi({
                ...requests.BookContainers({
                    id,
                    data: {
                        containers: data,
                    },
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessLongTermLeaseUpdate,
    })
}

export default useBookContainers
