import {
    RequestConfig,
} from 'app/types/request.types'

type RequestConfigOrderComment = {
    domainName: string,
    filterRequestAll: ({
        longTermLeaseId,
        params,
    }: {
        longTermLeaseId: number,
        params?: any
    }) => RequestConfig,
    CreateLongTermLeaseComment: ({
        longTermLeaseId,
    }) => RequestConfig,
    DeleteLongTermLeaseComment: ({
        id: number,
    }) => RequestConfig
}

const requestConfig: RequestConfigOrderComment = {
    domainName: 'OrderComments',
    filterRequestAll: ({
        longTermLeaseId,
        params,
    }) => {
        return {
            url: `longtermlease/${longTermLeaseId}/comment/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
    CreateLongTermLeaseComment: ({
        longTermLeaseId,
    }) => {
        return {
            url: `longtermlease/${longTermLeaseId}/comment`,
            method: 'POST',
        }
    },
    DeleteLongTermLeaseComment: ({
        id,
    }) => {
        return {
            url: `longtermlease/comment/${id}/archive`,
            method: 'PATCH',
        }
    },
}

export default requestConfig
