import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            '&&': {
                marginTop: theme.spacing(1.5),
            },
        },
    }
})

export default useStyles
