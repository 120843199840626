import {
    DTOCardFormField, NestedField,
} from 'app/types/form.types'

import {
    ServiceCenterFormType,
    OperationalDays,
    ServiceCenterStatus,
    ServiceCenterType,
} from '../serviceCenters.types'
import {
    AddressMainCategory,
} from '../../../ContactManagement/Locations/Locations.types'

const getFields = ({
    getAllowedValues,
    isProductionSite,
    isPlannedStatus,
    exists,
}: {
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    isProductionSite: boolean,
    isPlannedStatus: boolean,
    exists: boolean,
}): DTOCardFormField<keyof ServiceCenterFormType>[] => {
    return [
        {
            id: 'generalInformation',
            title: 'General Information',
            className: 'generalInformation',
            fields: [{
                id: 'column1',
                fields: [
                    {
                        name: 'serviceCenterNumber',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'ServiceCenter.serviceCenterNumber',
                    },
                    {
                        name: 'location',
                        componentName: 'LocationObjectSelector',
                        filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                        required: true,
                        labelKey: 'ServiceCenters.location',
                    },
                    {
                        name: 'serviceCenterStatus',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ServiceCenterStatus),
                        required: true,
                        labelKey: 'ServiceCenter.serviceCenterStatus',
                    },
                    ...isPlannedStatus ? [{
                        name: 'serviceCenterPlannedStartDate',
                        componentName: 'DateSelect',
                        required: true,
                        labelKey: 'ServiceCenter.serviceCenterPlannedStartDate',
                    }] as NestedField<keyof ServiceCenterFormType>[] : [],
                    {
                        name: 'serviceCenterType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ServiceCenterType),
                        useDropdown: true,
                        required: true,
                        labelKey: 'ServiceCenter.serviceCenterType',
                    },
                ],
            }],
        },
        {
            id: 'serviceCenterMetrics',
            title: 'Service Center Metrics',
            className: 'serviceCenterMetrics',
            fields: [
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'operationalDays',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(OperationalDays),
                            required: true,
                            useDropdown: true,
                            labelKey: 'ServiceCenter.operationalDays',
                        },
                        {
                            name: 'enhancedSkyCenterScanning',
                            componentName: 'Checkbox',
                            required: true,
                            labelKey: 'ServiceCenter.enhancedSkyCenterScanning',
                        },
                        {
                            name: 'orderSelectionDelay',
                            componentName: 'IntegerInput',
                            required: true,
                            labelKey: 'ServiceCenter.orderSelectionDelay',
                        },
                        {
                            name: 'maxTrucksPerDay',
                            componentName: 'IntegerInput',
                            required: true,
                            labelKey: 'ServiceCenter.maxTrucksPerDay',
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [{
                        name: 'processingThroughput',
                        componentName: 'IntegerInput',
                        required: true,
                        labelKey: 'ServiceCenter.processingThroughput',
                    }],
                },
            ],
        },
        {
            id: 'productMetrics',
            title: 'Product Metrics',
            className: 'productMetrics',
            fields: [{
                name: 'productMetrics',
                componentName: 'ProductMetrics',
                exists,
                required: true,
                labelKey: 'ServiceCenter.maxTrucksPerDay',
            }],
        },
        ...isProductionSite ? [{
            id: 'containerProductionRate',
            title: 'Container Production Rate',
            className: 'containerProductionRate',
            fields: [{
                name: 'containerProductionRate',
                componentName: 'ContainerProductionRate',
                required: true,
                exists,
                labelKey: 'ServiceCenter.containerProductionRate',
            }],
        }] as DTOCardFormField<keyof ServiceCenterFormType>[] : [],
        {
            id: 'storages',
            title: 'Storages',
            className: 'storages',
            fields: [{
                name: 'storages',
                componentName: 'StorageTable',
                labelKey: 'ServiceCenter.storages',
            }],
        },
    ]
}

export default getFields
