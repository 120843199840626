import React, {
    useMemo, useState,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
    getUrlsFromTableConfig, SkyNetDomainSubRoutes,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import SkyNetDomainForm from 'app/shared-components/SkyNetDomain/SkyNetDomainForm'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

import InternalTransportsOverview from './InternalTransportsOverview'
import orderRequest from '../orders.request'
import getTableConfig from './internalTransport.config'
import ContainerSelection from '../Components/ContainerSelection/ContainerSelectionContainer'
import OrderSteps from '../Components/OrderSteps'
import OrderNotificationsTab from '../Components/OrderNotificationsTab'
import useOrderManagementRoutes from '../../hooks/useOrderManagementRoutes'
import CreateInternalTransportForm from './CreateInternalTransportForm'
import InternalOrdersChangeHistory from './InternalOrdersChangeHistory'
import {
    InternalTransportTabs,
} from './InternalTransport.types'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const InternalTransport = () => {
    const {
        InternalOrders: InternalOrdersRoutes,
    } = useOrderManagementRoutes()

    const {
        state,
    } = useLocation()

    const [filterState] = useState(state?.isOverdueFilter)

    const tabs: SidebarTab[] = [
        {
            url: CommonRoutes.OVERVIEW,
            label: 'Overview',
            Component: InternalTransportsOverview,
        },
        {
            url: InternalTransportTabs.Notifications,
            label: 'Notifications',
            Component: OrderNotificationsTab,
        },
        {
            url: InternalTransportTabs.ContainerSelection,
            label: 'Containers / Freight',
            Component: ContainerSelection,
        },
        {
            url: InternalTransportTabs.OrderSteps,
            label: 'Order Steps',
            Component: OrderSteps,
        },
        {
            url: CommonRoutes.CHANGE_HISTORY,
            label: 'Change History',
            Component: InternalOrdersChangeHistory,
        },
    ]

    const customRoutes = useMemo(() => {
        return [{
            path: SkyNetDomainSubRoutes.CREATE,
            element: (
                <SkyNetDomainForm onClose={() => {
                    InternalOrdersRoutes.navigate()
                }}
                >
                    <CreateInternalTransportForm />
                </SkyNetDomainForm>
            ),
        }]
    }, [InternalOrdersRoutes])

    const tableConfig = useMemo(() => {
        return getTableConfig(filterState)
    }, [filterState])

    return (
        <SkyNetDomain
            name={orderRequest.dataModelName}
            sidebarTabs={tabs}
            uniqField="orderNumber"
            customRoutes={customRoutes}
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                moduleName={orderRequest.moduleName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default InternalTransport
