import {
    SkyNetTableConfig, SkyNetTableField,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    ProductType,
} from 'app/types/enums'

import {
    PoolingFields,
} from '../pooling.types'

export const deliveryConfirmationFields: SkyNetTableField<PoolingFields>[] = [
    {
        name: 'orderNumber',
        width: '100px',
        reduced: true,
    },
    {
        name: 'from',
        filterField: 'from',
        filterType: FilterType.Array,
        width: '80px',
        reduced: true,
    },
    {
        name: 'to',
        filterField: 'to',
        filterType: FilterType.Array,
        width: '80px',
        reduced: true,
    },
    {
        name: 'orderSubType',
        filterField: 'orderSubType',
        filterType: FilterType.Array,
        componentName: 'EnumValue',
        width: '150px',
        allowedValuesEnum: OrderSubType,
        reduced: true,
    },
    {
        name: 'forwarderLocationName',
        filterField: 'forwarderLocationName',
        filterType: FilterType.Array,
        labelKey: 'OrderPoolingInternalTransportsView.forwarderLocationName',
        width: '200px',
    },
    {
        name: 'originLocationLocationName',
        filterField: 'originLocationLocationName',
        filterType: FilterType.Array,
        width: '200px',
    },
    {
        name: 'firstStepDeliveryLocationLocationName',
        labelKey: 'OrderPoolingInternalTransportsView.firstStepDeliveryLocationLocationName',
        width: '200px',
        sorting: false,
    },
    {
        name: 'destinationLocationLocationName',
        filterField: 'destinationLocationLocationName',
        filterType: FilterType.Array,
        width: '250px',
    },
    {
        name: 'mainMeansOfTransport',
        componentName: 'TransportMode',
        headerProps: {
            children: 'Mode',
        },
        filterField: 'mainMeansOfTransport',
        filterType: FilterType.Array,
        width: '150px',
    },
    {
        name: 'requiredContainers',
        componentName: 'RequiredContainersList',
        allowedValuesEnum: ProductType,
        filterField: 'requiredContainersContainerModel',
        filterType: FilterType.Array,
        labelKey: 'OrderPoolingInternalTransportsView.requiredContainers',
        width: '200px',
        sorting: false,
    },
    {
        name: 'estimatedDeliveryLastStep',
        componentName: 'PoolingEstimatedPickup',
        labelKey: 'OrderPoolingInternalTransportsView.estimatedDelivery',
    },
]

export default (isOverdueFilter: boolean): SkyNetTableConfig<PoolingFields> => {
    return {
        name: 'Pooling.DeliveryConfirmation',
        tabs: [{
            name: 'Delivery: Confirmation',
            url: CommonRoutes.ALL,
            fields: deliveryConfirmationFields,
            filters: {
                isOverdue: isOverdueFilter,
            },
        }],
    }
}
