import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    ProductType,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    LongTermLeaseTableType, StatusLongTermLease,
} from './longTermLease.types'

export default (): SkyNetTableConfig<keyof LongTermLeaseTableType> => {
    return {
        name: 'OrderManagement.LongTermLeaseTable',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'longTermLeaseNumber',
                    reduced: true,
                    labelKey: 'LongTermLease.contractNumber',
                },
                {
                    name: 'customerExecutionLocationName',
                    filterField: 'customerExecutionLocationName',
                    filterType: FilterType.Array,
                    reduced: true,
                    labelKey: 'LongTermLease.customerExecutionCompanyName',
                },
                {
                    name: 'customerExecutionContactsContactName',
                    componentName: 'List',
                },
                {
                    name: 'pricingAccountCompanyName',
                    filterField: 'pricingAccountCompanyName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'requiredContainers',
                    componentName: 'RequiredContainersList',
                    allowedValuesEnum: ProductType,
                    sorting: false,
                },
                {
                    name: 'leaseStartTimestamp',
                    filterField: 'leaseStartDate',
                    filterType: FilterType.Array,
                },
                {
                    name: 'status',
                    filterField: 'status',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: StatusLongTermLease,
                },
            ],
        }],
    }
}
