import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    StorageTableType,
    StorageTypeEnum,
} from '../../../serviceCenters.types'

const LocationFormServiceCenterMetricsFields = ({
    getAllowedValues,
}): DTOCardFormField<keyof StorageTableType>[] => {
    return [{
        id: 'storage',
        title: 'General',
        className: 'storage',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'storageType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(StorageTypeEnum),
                    required: true,
                    labelKey: 'ServiceCenterStorage.storageType',
                }],
            },
            {
                id: 'column2',
                fields: [{
                    name: 'storageCapacity',
                    componentName: 'IntegerInput',
                    required: true,
                    labelKey: 'ServiceCenterStorage.storageCapacity',
                }],
            },
            {
                id: 'column3',
                fields: [{
                    name: 'preconditioningCapacity',
                    componentName: 'IntegerInput',
                    required: true,
                    labelKey: 'ServiceCenterStorage.preconditioningCapacity',
                }],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'processingTime',
                    componentName: 'IntegerInput',
                    required: true,
                    labelKey: 'ServiceCenterStorage.processingTime',
                    min: 1,
                    max: 3,
                }],
            },
        ],
    }]
}

export default LocationFormServiceCenterMetricsFields
