import React, {
    useCallback, useMemo,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import useLabel from 'app/hooks/useLabel'

import RichTableWithAddIcon from 'app/shared-components/RichTableWithAddIcon'
import request from '../../AccountLanes/AccountLanes.requests'
import getColumns from './LanesByAccount.columns'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'
import {
    AccountLanesTable,
} from '../../AccountLanes/AccountLanes.types'

export default function LanesByAccount({
    id,
}: Readonly<{ id: number}>) {
    const l = useLabel()
    const {
        Lanes: LanesRoutes,
    } = useAccountManagementRoutes()

    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])

    const {
        onParamsChange,
        loadData,
    } = useRequestTable<{data: AccountLanesTable[]}>({
        key: RequestKeys.getAccountLanesByCompanyNames,
        params: {},
        defaultValue: {
            filter: {
                accountId: [id],
            },
        },
        config: request.filterRequest,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    const openCreateForm = useCallback(() => {
        LanesRoutes.Create.navigate({
            options: {
                state: {
                    autofill: {
                        account: {
                            id,
                        },
                    },
                },
            },
        })
    }, [
        LanesRoutes.Create,
        id,
    ])

    const onRowClick = useCallback(({
        id: laneId,
    }: AccountLanesTable) => {
        LanesRoutes.Overview.navigate({
            params: {
                key: laneId,
            },
        })
    }, [LanesRoutes.Overview])

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    return (
        <RichTableWithAddIcon
            title="Lanes"
            onOpenForm={openCreateForm}
            name={request.domainName}
            configName="LanesByAccountTable"
            createEnabled={accountCreate}
            loadData={loadData}
            onParamsChange={onParamsChange}
            columns={columns}
            onRowClick={onRowClick}
        />
    )
}
