import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from '../longTermLease.request'
import {
    RejectContainersRequestData,
} from '../longTermLease.types'

const useRejectContainers = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessLongTermLeaseUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Container(s) Rejected',
        id: 'RejectDedicatedContainers',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            longTermLeaseId, data,
        }:{longTermLeaseId: number, data: RejectContainersRequestData[]}) => {
            return simpleApi({
                ...requests.RejectContainers({
                    longTermLeaseId,
                    data: {
                        rejectedBookedContainers: data,
                    },
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessLongTermLeaseUpdate,
    })
}

export default useRejectContainers
