import {
    RequestConfig,
} from 'app/types/request.types'

type BillingCompaniesRequest = {
    domainName: string,
    get: ({
        id: number,
    }) => RequestConfig,
    filterRequest: (p?: { params?: Record<string, any>}) => RequestConfig,
    create: () => RequestConfig,
    update: ({
        id: number,
    }) => RequestConfig,
    billingCompanyChangeSummary: {
        name: string,
        request: (id: number) => {
            customUrl: string,
            method: string,
            name: string,
        }
    }
}

const requests: BillingCompaniesRequest = {
    domainName: 'BillingCompanyDto',
    get: ({
        id,
    }) => {
        return {
            url: `billingcompany/${id}`,
            method: 'GET',
        }
    },
    filterRequest: ({
        params = {},
    } = {}) => {
        return {
            url: 'billingcompany/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    create: () => {
        return {
            url: 'billingcompany',
            method: 'POST',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `billingcompany/${id}/data`,
            method: 'PATCH',
        }
    },
    billingCompanyChangeSummary: {
        name: 'Modification',
        request: (billingCompanyNumber) => {
            return {
                customUrl: `billingcompany/${billingCompanyNumber}/modifications`,
                method: 'GET',
                name: 'BillingCompany',
            }
        },
    },
}

export default requests
