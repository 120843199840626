import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    TemperatureRange,
    RegionFirstLevel, OpportunityStatus,
} from 'app/types/enums'

import {
    OpportunityTableType,
} from '../../Opportunities/opportunities.types'

export default function getOpportunitiesByAccountColumns({
    l,
}: {l: (s: string) => string}): RichTableColumns<keyof OpportunityTableType>[] {
    return [
        {
            id: 'opportunityNumber',
            headerProps: {
                children: l('Opportunities.opportunityNumber'),
            },
            mapCellProps: 'opportunityNumber',
        },
        {
            id: 'temperatureRange',
            headerProps: {
                children: l('Opportunities.temperatureRange'),
            },
            mapCellProps: 'temperatureRange',
            componentName: 'EnumValue',
            allowedValuesEnum: TemperatureRange,
        },
        {
            id: 'originRegion',
            headerProps: {
                children: l('Opportunities.originRegion'),
            },
            mapCellProps: 'originRegion',
            componentName: 'EnumValue',
            allowedValuesEnum: RegionFirstLevel,
        },
        {
            id: 'destinationRegion',
            headerProps: {
                children: l('Opportunities.destinationRegion'),
            },
            mapCellProps: 'destinationRegion',
            componentName: 'EnumValue',
            allowedValuesEnum: RegionFirstLevel,
        },
        {
            id: 'reprOriginAirport',
            headerProps: {
                children: l('Opportunity.reprOriginAirport'),
            },
            mapCellProps: 'reprOriginAirport',
        },
        {
            id: 'reprDestinationAirport',
            headerProps: {
                children: l('Opportunity.reprDestinationAirport'),
            },
            mapCellProps: 'reprDestinationAirport',
        },
        {
            id: 'opportunityStatus',
            headerProps: {
                children: l('Opportunity.status'),
            },
            mapCellProps: 'opportunityStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: OpportunityStatus,
        },
        {
            id: 'collaborators',
            headerProps: {
                children: l('Opportunity.opportunityCollaborators'),
            },
            componentName: 'List',
            mapCellProps: 'collaborators',
        },
    ]
}
