import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from '../longTermLease.request'

const useUpdateStatus = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessLongTermLeaseUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Long-Term Lease was updated',
        id: 'LongTermLeaseUpdate',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            id,
            action,
        }: {
            id: number,
            action: string,
        }) => {
            return simpleApi({
                ...requests.UpdateStatus({
                    id,
                    action,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessLongTermLeaseUpdate,
    })
}

export default useUpdateStatus
