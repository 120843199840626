import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import useGetProductTypesForTempRange from 'app/Apps/PreBooking/components/PreBookingLaneDetails/hooks/useGetProductTypesForTempRange'

import useModifyRequiredContainers from '../../hooks/useModifyRequiredContainers'
import getFields from './modifyRequiredContainersDialog.fields'
import {
    ModificationRequiredContainersType,
} from '../../longTermLease.types'
import useStyles from './ModifyRequiredContainersDialog.style'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void
    requiredContainers: RequiredContainersCustomer[],
    longTermLeaseId: number,
    tempRange: LaneGroupTemperatureRange,
    onSuccess: () => void
}

const DIALOG_TITLE = 'REQUIRED CONTAINER MODIFICATION'

const ModifyRequiredContainersDialog = ({
    open,
    setOpen,
    onSuccess,
    longTermLeaseId,
    requiredContainers,
    tempRange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const [
        value,
        setValue,
    ] = useState<Partial<ModificationRequiredContainersType>>({
        requiredContainers,
    })

    const {
        productTypes,
    } = useGetProductTypesForTempRange({
        tempRange,
    })

    const onSuccessModification = (): void => {
        setOpen(false)
        onSuccess()
    }

    const {
        isPending: isLoading,
        mutate,
    } = useModifyRequiredContainers(onSuccessModification)

    const modifyRequiredContainers = useCallback((): void => {
        mutate({
            longTermLeaseId,
            data: value,
        })
    }, [
        longTermLeaseId,
        mutate,
        value,
    ])

    const handleClose = (action: boolean): void => {
        if (!action) {
            setOpen(false)
            setValue({
                requiredContainers,
            })

            return
        }

        modifyRequiredContainers()
    }

    const fields = useMemo(() => {
        return getFields({
            allowedProductTypes: productTypes,
            getAllowedValues,
        })
    }, [
        getAllowedValues,
        productTypes,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="modify"
            negativeLabel="cancel"
            isLoading={isLoading}
            classNames={{
                paper: classes.paper,
            }}
        >
            <DomainObjectForm
                fields={fields}
                name="ModifyRequiredContainers"
                value={value}
                onChange={setValue}
                className={classes.gridWrapper}
                wrapper="simple"
            />
        </ConfirmationDialog>
    )
}

export default ModifyRequiredContainersDialog
