import React from 'react'

import useGetPreBookingComments from './hooks/useGetPreBookingComments'
import CommentsTable from '../../../OrderManagement/CommentsTable'
import useRemovePreBookingComment from './hooks/useRemovePreBookingComment'
import requestConfig from './preBookingComment.request'
import useStyles from './preBookingComment.style'

const PreBookingComment = ({
    id,
}: {
    id: number,
}) => {
    const {
        classes,
    } = useStyles()
    const {
        onParamsChange,
        loadData,
        refetch,
    } = useGetPreBookingComments(id)

    const deleteComment = useRemovePreBookingComment({
        onSuccess: refetch,
    })

    return (
        <CommentsTable
            requestConfigCreate={requestConfig.CreatePreBookingComment({
                id,
            })}
            deleteComment={deleteComment}
            onParamsChange={onParamsChange}
            loadData={loadData}
            refetch={refetch}
            className={classes.root}
        />
    )
}

export default PreBookingComment
