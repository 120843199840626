import React from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import useGetAccount from '../../hooks/useGetAccount'
import AddressesByAccountCompany from './AddressesByAccountCompany'

export default function AddressesByAccount({
    id,
}: Readonly<{id: number}>) {
    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
    } = useGetAccount(id)

    return (
        <StatusHandler
            isSuccess={isDataReady && Boolean(data?.company?.id)}
            isError={isError}
            error={error}
            isFetching={isFetching}
        >
            <AddressesByAccountCompany id={data?.company?.id} />
        </StatusHandler>

    )
}
