import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    AccountQuoteTable, QuoteStatus,
} from '../../AccountQuotes/AccountQuotes.types'

export default function getAccountQuoteTable({
    l,
}: {l: (s: string) => string}): RichTableColumns<keyof AccountQuoteTable>[] {
    return [
        {
            id: 'quoteNumber',
            headerProps: {
                children: l('AccountQuotes.quoteNumber'),
            },
            mapCellProps: 'quoteNumber',
        },
        {
            id: 'quotedForCompanyLocationName',
            headerProps: {
                children: l('AccountQuotes.quotedForCompany'),
            },
            mapCellProps: 'quotedForCompanyLocationName',
        },
        {
            id: 'originAirportsIataCode',
            headerProps: {
                children: l('AccountLane.originAirport'),
                sorting: false,
            },
            mapCellProps: 'originAirportsIataCode',
            componentName: 'List',
        },
        {
            id: 'destinationAirportsIataCode',
            headerProps: {
                children: l('AccountLane.destinationAirport'),
                sorting: false,
            },
            mapCellProps: 'destinationAirportsIataCode',
            componentName: 'List',
        },
        {
            id: 'currency',
            headerProps: {
                children: l('AccountQuotes.currency'),
            },
            mapCellProps: 'currency',
        },
        {
            id: 'quoteStatus',
            headerProps: {
                children: l('AccountQuotes.quoteStatus'),
            },
            mapCellProps: 'quoteStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: QuoteStatus,
        },
        {
            id: 'pricingResponsible',
            headerProps: {
                children: l('AccountQuotes.pricingResponsible'),
            },
            mapCellProps: 'pricingResponsible',
            componentName: 'List',
        },
    ]
}
