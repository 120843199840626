import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    ProductType,
    LaneGroupTemperatureRange,
} from 'app/types/enums'

import {
    LongTermLeaseType,
} from '../longTermLease.types'

const getRequiredContainersFields = ({
    getAllowedValues,
}: {
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>
}): DTOCardFormField<keyof LongTermLeaseType>[] => {
    return [{
        title: 'Required Containers',
        className: 'requiredContainers',
        id: 'containers',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'requiredContainers',
                    componentName: 'RequiredContainerByProductTypeList',
                    allowedProductTypes: Object.keys(ProductType) as ProductType[],
                    labelKey: 'OrderByNumberView.requiredContainers',
                }],
            },
            {
                id: 'column2',
                fields: [{
                    name: 'tempRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                    labelKey: 'OrderByNumberView.temperatureRange',
                }],
            },
        ],
    }]
}

export default getRequiredContainersFields
