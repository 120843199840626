import React from 'react'

import useGetOrderComments from './hooks/useGetOrderComments'
import requestsConfig from './orderComment.request'
import CommentsTable from '../../../CommentsTable'
import useRemoveOrderComment from './hooks/useRemoveOrderComment'

const OrderComment = ({
    orderNumber,
}: {
    orderNumber: string,
}) => {
    const {
        onParamsChange,
        loadData,
        refetch,
    } = useGetOrderComments(orderNumber)

    const deleteComment = useRemoveOrderComment({
        onSuccess: refetch,
    })

    return (
        <CommentsTable
            requestConfigCreate={requestsConfig.CreateOrderComment({
                orderNumber,
            })}
            deleteComment={deleteComment}
            onParamsChange={onParamsChange}
            loadData={loadData}
            refetch={refetch}
        />
    )
}

export default OrderComment
