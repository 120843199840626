import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    DefaultTemperatureRange,
} from 'app/types/enums'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'

import {
    AvailableContainersType,
} from '../../longTermLease.types'

export default ({
    availableContainersLinkConfig,
}: {
    availableContainersLinkConfig?: {
        path: string[],
        params: Record<string, string>,
    }
}): SkyNetTableConfig<keyof AvailableContainersType> => {
    return {
        name: 'AvailableContainersTable',
        fields: [
            {
                name: 'serialNumber',
                width: '160px',
                componentName: 'SkyNetTableLink',
                linkConfig: availableContainersLinkConfig,
                type: TypeLink.EXTERNAL,
                size: SizeLink.S,
            },
            {
                name: 'jypId',
                labelKey: 'Container.jypNumber',
                width: '150px',
            },
            {
                name: 'containerStatus',
                componentName: 'ContainerStatusEnriched',
                filterField: 'containerStatus',
                filterType: FilterType.Array,
                width: '220px',
            },
            {
                name: 'tempRange',
                componentName: 'EnumValue',
                allowedValuesEnum: DefaultTemperatureRange,
                filterField: 'tempRange',
                filterType: FilterType.Array,
                width: '150px',
            },
            {
                name: 'containerTypeContainerModel',
                filterField: 'containerTypeContainerModel',
                filterType: FilterType.Array,
            },
            {
                name: 'locationLocationName',
                filterField: 'locationLocationName',
                filterType: FilterType.Array,
                labelKey: 'Contact.location',
            },
        ],
    }
}
