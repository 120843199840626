import React from 'react'

import useLabel from 'app/hooks/useLabel'

import {
    ContainerAvailabilityForecastContainerStatuses,
} from 'app/Apps/ContainerManagement/ServiceCenters/serviceCenters.types'
import useStyles from './CustomTooltip.styles'

export default function CustomTooltip({
    date,
    minPreconditioned,
    statuses,
}: Readonly<{
    date: string,
    minPreconditioned: number,
    statuses: Record<ContainerAvailabilityForecastContainerStatuses, number>,
}>) {
    const {
        classes,
        cx,
    } = useStyles()

    const total = Object.values(statuses).reduce((acc, item) => {
        return item ? acc + item : acc
    }, 0)

    const l = useLabel()

    const {
        preconditioned,
        ...restStatuses
    } = statuses

    return (
        <div className={classes.tooltipWrapper}>
            <div className={classes.wrapper}>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.date')}</div>
                    <div>{date}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.total')}</div>
                    <div>{total}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.minPreconditioned')}</div>
                    <div>{minPreconditioned}</div>
                </div>
            </div>
            <div className={classes.wrapper}>
                {Object.entries(restStatuses).map(([
                    key,
                    value,
                ]) => {
                    return (Boolean(value) || value === 0) && (
                        <div
                            className={classes.item}
                            key={key}
                        >
                            <div className={classes.label}>{l(`Monitoring.${key}`)}</div>
                            <div className={classes.valueWrapper}>
                                <div
                                    data-testid={key}
                                    className={cx(classes.marker,
                                        value < 0 ? classes.error : classes?.[key])}
                                />
                                <div
                                    className={classes.value}
                                >
                                    {value}
                                </div>
                            </div>
                        </div>
                    )
                })}
                { (Boolean(preconditioned) || preconditioned === 0) && (
                    <div
                        className={classes.item}
                        key="preconditioned"
                    >
                        <div className={classes.label}>{l('Monitoring.preconditioned')}</div>
                        <div className={classes.valueWrapper}>
                            <div
                                data-testid="preconditioned"
                                className={cx(
                                    classes.marker,
                                    preconditioned < minPreconditioned
                                        ? classes.error
                                        : classes.preconditioned,
                                )}
                            />
                            <div
                                className={classes.value}
                            >
                                {preconditioned}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
