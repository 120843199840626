import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requests from '../AccountQuotes.requests'
import {
    AccountQuote,
} from '../AccountQuotes.types'

const useGetAccountQuote = (id: number) => {
    return useRequest<AccountQuote>({
        key: RequestKeys.getAccountQuote,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.get),
        enabled: Boolean(id),
        keepPreviousData: true,
    })
}

export default useGetAccountQuote
