import React from 'react'
import {
    useLocation,
} from 'react-router-dom'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import RelationshipsManagementRequests from '../../RelationshipsManagement.request'
import ActivityRecordForm from '../ActivityRecordForm'

const ActivityRecordCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const {
        state,
    } = useLocation()

    return (
        <ActivityRecordForm
            data={state || {}}
            onSuccess={onSuccess}
            requestParam={RelationshipsManagementRequests.create.request()}
        />
    )
}

export default ActivityRecordCreate
