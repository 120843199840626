import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    CommentType,
} from 'app/Apps/OrderManagement/CommentsTable/commentsTable.types'

import requestConfig from '../preBookingComment.request'

const useGetPreBookingComments = (id: number) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<CommentType>({
        key: RequestKeys.getPreBookingComments,
        params: {
            id,
        },
        config: requestConfig.filterRequestAll,
        keepPreviousData: true,
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    }
}

export default useGetPreBookingComments
