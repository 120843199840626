import {
    useMemo,
} from 'react'
import useRequest, {
    RequestFn,
    RequestKeys,
} from 'app/hooks/useRequest'
import config from 'app/Apps/ContainerManagement/Containers/Containers.request'
import {
    ContainerStatus,
} from 'app/types/enums'
import useEnumLabels from 'app/hooks/useEnumLabels'

export default function useGetAllowedContainerStatusTransitions(currentStatus: ContainerStatus) {
    const getLabels = useEnumLabels()
    const {
        data,
        isDataReady,
    } = useRequest<{
        allowedStatusTransitions: ContainerStatus[];
    }>({
        key: RequestKeys.getAllowedContainerStatusTransitions,
        enabled: Boolean(currentStatus),
        requestFunc: RequestFn.getEntity(config.getAllowedContainerStatusTransitions),
    })

    return useMemo(() => {
        if (isDataReady && data?.allowedStatusTransitions) {
            return getLabels(data?.allowedStatusTransitions?.[currentStatus] || [])
        }

        return {}
    }, [
        isDataReady,
        data?.allowedStatusTransitions,
        getLabels,
        currentStatus,
    ])
}
