/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
    useCallback,
} from 'react'
import clsx from 'clsx'
import {
    FormControl,
} from '@mui/material'
import omit from 'lodash/omit'

import Radio from 'app/shared-components/Radio'
import Select from 'app/shared-components/Select'
import useGetUntypedEnumLabel from 'app/hooks/useEnumValues/useGetUntypedEnumLabel'

import createInputHandler from 'app/utils/createInputHandler'
import useStyles from './EnumSelector.styles'

const sortFunc = (a, b) => {
    return ((a.label > b.label) ? 1 : -1)
}

const defaultProps = {
    className: '',
    title: '',
    value: undefined,
    disabled: false,
    useDropdown: false,
    noSort: false,
    required: false,
    showInColumns: false,
    showOutOfRangeOption: false,
    onChange: undefined,
}

export default function EnumSelector(props: Readonly<{
    allowedValues: Record<string, string>,
    value?: string,
    title?: string,
    onChange: (...args: any[]) => void,
    name: string,
    disabled?: boolean,
    className?: string,
    useDropdown?: boolean,
    noSort?: boolean,
    showOutOfRangeOption?: boolean,
}>) {
    const {
        allowedValues,
        value,
        title,
        onChange,
        name,
        disabled,
        className,
        useDropdown,
        noSort,
        showOutOfRangeOption,
    } = props
    const {
        classes,
    } = useStyles()
    const getLabels = useGetUntypedEnumLabel()

    const allowedValuesArray = Object.entries(allowedValues)

    const changeHandler = useMemo(() => {
        return createInputHandler(onChange)
    }, [onChange])

    const onRadioChange = useCallback((newValue) => {
        onChange(newValue)
    }, [onChange])

    const radioOptions = useMemo(() => {
        const array = allowedValuesArray
            .map(([
                key,
                val,
            ]) => {
                return {
                    label: val || key, value: key,
                }
            })

        if (noSort) {
            return array
        }
        return array.sort(sortFunc)
    }, [
        allowedValuesArray,
        noSort,
    ])

    const labelOutOfRangeValue = useMemo(() => {
        if (!showOutOfRangeOption) return undefined
        return getLabels(value)
    }, [
        value,
        getLabels,
        showOutOfRangeOption,
    ])

    return (
        <FormControl
            component="fieldset"
            className={clsx(classes.root, className)}
            disabled={disabled}
        >
            {disabled || useDropdown || allowedValuesArray.length > 5
                ? (
                    <Select
                        {...omit(props, 'className')}
                        onChange={changeHandler}
                        disableSorting={noSort}
                        showOutOfRangeOption={showOutOfRangeOption}
                        labelOutOfRangeValue={labelOutOfRangeValue}
                    />
                )
                : (
                    <Radio
                        {...props}
                        title={title}
                        name={name}
                        value={value}
                        options={radioOptions}
                        onChange={onRadioChange}
                        compact
                    />
                )}
        </FormControl>
    )
}

EnumSelector.defaultProps = defaultProps
