import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    BillingCompany,
} from 'app/Apps/ContactManagement/BillingCompanies/BillingCompanyForm.types'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof BillingCompany>[] => {
    return [
        {
            id: 'billingLocationName',
            headerProps: {
                children: l('BillingCompanyView.billingLocationName'),
            },
            mapCellProps: 'billingLocationName',
            minWidth: '18%',
        },
        {
            id: 'street',
            headerProps: {
                children: l('BillingCompanyView.street'),
            },
            mapCellProps: 'street',
            minWidth: '20%',
        },
        {
            id: 'zip',
            headerProps: {
                children: l('BillingCompanyView.zip'),
            },
            mapCellProps: 'zip',
            width: '7%',
        },
        {
            id: 'city',
            headerProps: {
                children: l('BillingCompanyView.city'),
            },
            mapCellProps: 'city',
            width: '10%',
        },
        {
            id: 'countryCountryName',
            headerProps: {
                children: l('BillingCompanyView.countryCountryName'),
            },
            mapCellProps: 'countryCountryName',
            width: '10%',
        },
        {
            id: 'companyCompanyName',
            headerProps: {
                children: l('BillingCompanyView.companyCompanyName'),
            },
            mapCellProps: 'companyCompanyName',
            width: '15%',
        },
        {
            id: 'isSyncedWithAbacus',
            componentName: 'ChipCheckMark',
            headerProps: {
                children: l('BillingCompanyView.isSyncedWithAbacus'),
            },
            mapCellProps: 'isSyncedWithAbacus',
            width: '10%',
        },
        {
            id: 'abacusKeyCustomerNumber',
            headerProps: {
                children: l('BillingCompanyView.abacusKeyCustomerNumber'),
            },
            mapCellProps: 'abacusKeyCustomerNumber',
            width: '10%',
        },
    ]
}

export default columns
