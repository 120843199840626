import {
    useMemo,
} from 'react'
import {
    createRoutesConfig,
    useSkyNetNavigate, App, CommonRoutes,
} from 'app/SkyNetRoutes'
import useCreateSkyNetDomainRoutes, {
    SkyNetDomainSubRoutes,
} from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'
import {
    LocationType,
} from 'app/types/enums'
import {
    createChildEditRoutes,
} from 'app/shared-components/SkyNetDomain'
import {
    ContactManagementDomains,
} from '../ContactManagement.types'
import {
    CompaniesTabs,
} from '../Companies/Companies.types'
import {
    LocationsTabs,
} from '../Locations/Locations.types'
import {
    getTabUrl,
} from '../Locations/Locations.config'
import {
    ContactsTableTabs,
} from '../Contacts/Contacts.config'
import {
    AirportsAndSeaportsDomains,
} from '../AirportsAndSeaports/AirportsAndSeaports.enums'

export default function useContactManagementRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const createSkyNetDomainRoutes = useCreateSkyNetDomainRoutes()

    return useMemo(() => {
        const SkyNetDomainRoutes = createSkyNetDomainRoutes()
        const locationsTabs = {
            Overview: CommonRoutes.OVERVIEW,
            Update: CommonRoutes.DATA_UPDATE,
            Areas: LocationsTabs.Areas,
            ChangeHistory: CommonRoutes.CHANGE_HISTORY,
        }
        const config = {
            path: [App.CONTACT_MANAGEMENT],
            Companies: {
                route: ContactManagementDomains.Companies,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                SkyMind: {
                    route: CompaniesTabs.SkyMind,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            Locations: {
                route: ContactManagementDomains.Locations,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Areas: {
                    route: LocationsTabs.Areas,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Address: {
                    route: getTabUrl(LocationType.ADDRESS),
                    Edit: {
                        route: SkyNetDomainRoutes.All.Edit.route,
                        params: [{
                            name: 'key',
                            required: true,
                        }],
                        Update: {
                            route: CommonRoutes.DATA_UPDATE,
                        },
                    },
                },
                Create: {
                    route: null,
                    via: SkyNetDomainRoutes.All.Create,
                },
            },
            BillingCompanies: {
                route: ContactManagementDomains.BillingCompanies,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Create: {
                    route: null,
                    via: SkyNetDomainRoutes.All.Create,
                },
            },
            AirportsAndSeaports: {
                route: ContactManagementDomains.AirportsAndSeaports,
                ...createChildEditRoutes(
                    createSkyNetDomainRoutes(AirportsAndSeaportsDomains),
                )(locationsTabs),
            },
            Contacts: {
                route: ContactManagementDomains.Contacts,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Create: {
                    route: null,
                    via: SkyNetDomainRoutes.All.Create,
                },
                Prospect: {
                    route: ContactsTableTabs.Prospect,
                    Edit: {
                        route: SkyNetDomainSubRoutes.EDIT,
                        params: [{
                            name: 'key',
                            required: true,
                        }],
                        Overview: {
                            route: CommonRoutes.OVERVIEW,
                        },
                    },
                },
            },
            Users: {
                route: ContactManagementDomains.Users,
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        createSkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
