import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    StorageTableType, StorageTypeEnum,
} from '../../serviceCenters.types'

const columns = (l: (l:string) => string): RichTableColumns<keyof StorageTableType>[] => {
    return [
        {
            id: 'storageType',
            headerProps: {
                children: l('ServiceCenterStorage.storageType'),
            },
            mapCellProps: 'storageType',
            componentName: 'EnumValue',
            allowedValuesEnum: StorageTypeEnum,
        },
        {
            id: 'storageCapacity',
            headerProps: {
                children: l('ServiceCenterStorage.storageCapacity'),
            },
            mapCellProps: 'storageCapacity',
        },
        {
            id: 'preconditioningCapacity',
            headerProps: {
                children: l('ServiceCenterStorage.preconditioningCapacity'),
            },
            mapCellProps: 'preconditioningCapacity',
        },
        {
            id: 'processingTime',
            headerProps: {
                children: l('ServiceCenterStorage.processingTime'),
            },
            mapCellProps: 'processingTime',
        },
    ]
}

export default columns
