import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            '&&': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        title: {
            '&&': {
                paddingLeft: theme.spacing(2),
            },
        },
        root: {
            minHeight: 300,
            maxHeight: 500,
        },
    }
})

export default useStyles
