import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import request from '../longTermLease.request'
import {
    LongTermLeaseType,
} from '../longTermLease.types'

const useGetLongTermLease = (id: number) => {
    const queryClient = useQueryClient()

    const invalidate = () => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getLongTermLease],
        })
    }

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<LongTermLeaseType>({
        key: RequestKeys.getLongTermLease,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(request.Get),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetLongTermLease
