import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    LaneGroupTemperatureRange,
    ProductType,
} from 'app/types/enums'

import request from 'app/shared-components/Fees/Fee.request'

const useGetProductTypesForTempRange = ({
    tempRange: selectedTempRange,
}: {
    tempRange?: LaneGroupTemperatureRange,
}): {
    productTypes: ProductType[],
    isDataReady: boolean,
    error: Record<string, any>,
    isFetching: boolean,
    isError: boolean,
} => {
    const {
        data: productTypes,
        isDataReady,
        error,
        isError,
        isFetching,
    } = useRequest<ProductType[]>({
        key: RequestKeys.getProductTypesContractBasis,
        params: {
            selectedTempRange,
        },
        keepPreviousData: true,
        requestFunc: RequestFn.getEntity(request.ProductType.requestFn, (promise) => {
            return promise.then((resp) => {
                return resp.data.reduce((acc, {
                    productType,
                    temperatureRange,
                }) => {
                    if (!selectedTempRange || selectedTempRange === temperatureRange) {
                        return [
                            ...acc,
                            productType,
                        ]
                    }

                    return acc
                }, [])
            })
        }),
    })

    return {
        productTypes,
        isDataReady,
        error,
        isFetching,
        isError,
    }
}

export default useGetProductTypesForTempRange
