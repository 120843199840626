import {
    RequestConfig,
} from 'app/types/request.types'

import {
    ModificationRequiredContainersType, RejectContainersRequestData,
} from './longTermLease.types'

type LongTermLeaseRequests = {
    Create: ({
        data,
    }) => RequestConfig,
    Get: ({
        id: number,
    }) => RequestConfig,
    UpdateStatus: ({
        id: number,
        action: string,
    }) => RequestConfig,
    BookContainers: ({
        id: number,
        data,
    }) => RequestConfig,
    ModifyRequiredContainers: ({
        longTermLeaseId,
        data,

    }: {
        longTermLeaseId: number,
        data: Partial<ModificationRequiredContainersType>,
    }) => RequestConfig,
    RejectContainers: ({
        longTermLeaseId,
        data,

    }: {
        longTermLeaseId: number,
        data: {rejectedBookedContainers: RejectContainersRequestData[]},
    }) => RequestConfig,
    GetDedicatedContainers: ({
        longTermLeaseId,
    }: {
        longTermLeaseId: number,
    }) => RequestConfig,
    GetAvailableContainers: RequestConfig,
}

const longTermLeaseRequests: LongTermLeaseRequests = {
    Create: ({
        data,
    }) => {
        return {
            url: 'longtermlease',
            method: 'POST',
            data,
        }
    },
    Get: ({
        id,
    }) => {
        return {
            url: `longtermlease/${id}`,
            method: 'GET',
        }
    },
    UpdateStatus: ({
        id,
        action,
    }) => {
        return {
            url: `longtermlease/${id}/status/${action}`,
            method: 'PATCH',
        }
    },
    BookContainers: ({
        id,
        data,
    }) => {
        return {
            url: `longtermlease/${id}/bookedcontainer`,
            method: 'POST',
            data,
        }
    },
    ModifyRequiredContainers: ({
        longTermLeaseId,
        data,
    }) => {
        return {
            url: `longtermlease/${longTermLeaseId}/requiredcontainers`,
            method: 'PATCH',
            data,
        }
    },
    GetAvailableContainers: {
        url: 'container/filter-request/longtermleaseavailable',
        method: 'POST',
    },
    GetDedicatedContainers: ({
        longTermLeaseId,
    }) => {
        return {
            url: `longtermlease/${longTermLeaseId}/bookedcontainer/filter-request/all`,
            method: 'POST',
            data: {
                rows: 500,
            },
        }
    },
    RejectContainers: ({
        longTermLeaseId,
        data,
    }) => {
        return {
            url: `longtermlease/${longTermLeaseId}/bookedcontainer/rejection`,
            method: 'PATCH',
            data,
        }
    },
}

export default longTermLeaseRequests
