import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import {
    AddressCategory,
} from 'app/types/enums'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof Location>[] => {
    return [
        {
            id: 'locationName',
            headerProps: {
                children: l('Location.locationName'),
            },
            mapCellProps: 'locationName',
            width: '40%',
        },
        {
            id: 'addressLine1',
            headerProps: {
                children: l('Location.addressLine1'),
            },
            mapCellProps: 'addressLine1',
        },
        {
            id: 'zip',
            headerProps: {
                children: l('Location.zip'),
            },
            mapCellProps: 'zip',
        },
        {
            id: 'city',
            headerProps: {
                children: l('Location.city'),
            },
            mapCellProps: 'city',
        },
        {
            id: 'countryName',
            headerProps: {
                children: l('Location.country'),
            },
            mapCellProps: 'countryName',
        },
        {
            id: 'isMainAddress',
            componentName: 'ChipCheckMark',
            headerProps: {
                children: l('Location.isMainAddress'),
            },
            mapCellProps: 'isMainAddress',
        },
        {
            id: 'addressCategory',
            headerProps: {
                children: l('Location.addressCategory'),
            },
            componentName: 'EnumList',
            allowedValuesEnum: AddressCategory,
            mapCellProps: 'addressCategory',
        },
    ]
}

export default columns
