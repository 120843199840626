import React, {
    useState,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import {
    useSharedContext,
} from 'app/shared-components/SharedContext'

import fields from './commentCreateForm.fields'
import useStyles from './CommentCreateForm.style'

const CommentCreateForm = ({
    onCancel,
    onSuccess,
}: {
    onSuccess: (...args: any[]) => void,
    onCancel: (...args: any[]) => void,
}) => {
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState<{comment: string}>(null)

    const onSuccessCreate = () => {
        setValue(null)
        onSuccess()
    }

    const {
        requestConfigCreate,
    } = useSharedContext()

    return (
        <FormWithControls
            name="OrderComments"
            fields={fields}
            value={value}
            setExternalValue={setValue}
            onCancel={onCancel}
            onSuccess={onSuccessCreate}
            requestParams={requestConfigCreate}
            className={classes.root}
            cancelEnabled
        />
    )
}

export default CommentCreateForm
