import React, {
    useCallback, useMemo,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    ROLES_PROSPECT_ALLOWED,
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import useHasPermissionType from 'app/Apps/ContactManagement/utils/useHasPermissionType'
import useHasPermission from 'app/hooks/useHasPermission'
import request from 'app/Apps/ContactManagement/Locations/Locations.request'
import useLabel from 'app/hooks/useLabel'
import useContactManagementRoutes from 'app/Apps/ContactManagement/hooks/useContactManagementRoutes'
import RichTableWithAddIcon from 'app/shared-components/RichTableWithAddIcon'
import getColumns from './AddressesByAccountCompany.columns'

export default function AddressesByAccountCompany({
    id,
}: Readonly<{id: number}>) {
    const l = useLabel()
    const {
        Locations: LocationsRoutes,
    } = useContactManagementRoutes()

    const hasCreatePermissionsByMainCategory = useHasPermissionType([], 'LocationAccessRights', 'create')
    const hasCreatePermissionForProspectLocation = useHasPermission(ROLES_PROSPECT_ALLOWED)
    const hasCreatePermissions = hasCreatePermissionsByMainCategory
        || hasCreatePermissionForProspectLocation

    const {
        onParamsChange,
        loadData,
    } = useRequestTable<{data: Location[]}>({
        key: RequestKeys.getLocationsByAccountCompany,
        params: {},
        defaultValue: {
            filter: {
                companyId: [id],
            },
        },
        config: request.all,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    const openCreateForm = useCallback(() => {
        LocationsRoutes.Create.navigate({
            options: {
                state: {
                    company: {
                        id,
                    },
                },
            },
        })
    }, [
        LocationsRoutes.Create,
        id,
    ])

    const onRowClick = useCallback(({
        id: locationId,
    }: Location) => {
        LocationsRoutes.Overview.navigate({
            params: {
                key: locationId,
            },
        })
    }, [LocationsRoutes.Overview])

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    return (
        <RichTableWithAddIcon
            title="Addresses"
            onOpenForm={openCreateForm}
            name={request.domainName}
            configName="LocationsByAccountTableTable"
            createEnabled={hasCreatePermissions}
            loadData={loadData}
            onParamsChange={onParamsChange}
            columns={columns}
            onRowClick={onRowClick}
        />
    )
}
