import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    CommentType,
} from './commentsTable.types'

const getColumns = (l: (key:string)=> string): RichTableColumns<keyof CommentType>[] => {
    return [
        {
            id: 'createdBy',
            headerProps: {
                children: l('OrderComment.createdBy'),
            },
            mapCellProps: 'createdBy',
        },
        {
            id: 'comment',
            headerProps: {
                children: l('OrderComment.comment'),
            },
            mapCellProps: 'comment',
        },
        {
            id: 'createdOn',
            headerProps: {
                children: l('OrderComment.createdOn'),
            },
            mapCellProps: 'changedOn',
        },
        {
            id: 'delete',
            headerProps: {
                children: '',
            },
            mapCellProps: 'delete',
            width: '50px',
            componentName: 'RemoveComment',
        },
    ]
}

export default getColumns
