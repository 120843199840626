import Temperature from 'app/shared-components/Temperature'
import ModifiedValue from 'app/shared-components/ModifiedValue'
import Checkbox from 'app/shared-components/Checkbox'
import AmountOfTime from 'app/shared-components/AmountOfTime'
import {
    MultipleEnumComponents, MultipleEnumComponentConfig,
} from 'app/shared-components/EnumListMultipleColored'
import RemoveComment from 'app/Apps/OrderManagement/CommentsTable/RemoveComment'

import WorkingDaysLeftAlert from 'app/shared-components/WorkingDaysLeftAlert'
import WorkingDaysLeftAlertConfig from 'app/shared-components/WorkingDaysLeftAlert/WorkingDaysLeftAlert.types'
import List from './List'
import Boolean from './Boolean'
import ChipCheckMark from './ChipCheckMark'
import LoggerAge, {
    LoggerAgeFromDate,
} from './LoggerAge'
import StatusAge from './StatusAge'
import Link from './Link'
import ContainerStatusEnriched from './ContainerStatusEnriched'
import AdditionalFeesStatusEnriched from './AdditionalFeesStatusEnriched'
import LaneFeesStatusEnriched from './LaneFeesStatusEnriched'
import TemperatureCelsius from './TemperatureCelsius'
import TemperatureAge from './TemperatureAge'
import ContainerTempRange from './ContainerTempRange'
import ContainerTemp from './ContainerTemp'
import LaneStatus from './LaneStatus'
import LaneDateEnriched from './LaneDateEnriched'
import ContainerSelectionUntil from './ContainerSelectionUntil'
import Age from './TemperatureAge/Age'
import ContainerForPooling from './ContainerForPooling'
import StepProgress from './StepProgress'
import NotificationType from './NotificationType'
import LastSentOn from './LastSentOn'
import Recipients from './Recipients'
import SendTimeCell from './SendTimeCell'
import CloudIconBoolean from './CloudIconBoolean'
import TransportMode from './TransportMode'
import {
    LabelsComponents, LabelsComponentConfig,
} from './ModifiedField'
import {
    EnumComponents, EnumComponentConfig,
} from './EnumComponents.config.types'
import PoolingRequestedDelivery from './PoolingRequestedDelivery'
import PoolingEstimatedPickup from './PoolingEstimatedPickup'
import EstimatedDeliveryDeparture from './EstimatedDeliveryDeparture'
import RequestedDeliveryAddInfo from './RequestedDeliveryAddInfo'
import DownloadDocument from './DownloadDocument'
import QuotePdfDelete from './QuotePdfDelete'
import QuotePdfDescriptionEdit from './QuotePdfDescriptionEdit'
import LoggerDocumentDelete from './LoggerDocumentDelete'
import TableMultipleValues, {
    MultipleValuesComponentsConfig,
} from './TableMultipleValues'
import SkyNetTableLink, {
    SkyNetTableLinkConfig,
} from './SkyNetTableLink'

/* This object should contain all component without
special props requirements in config that we will use as componentName in:
 SkyNetTable(now) and RichTableReduxWrapper */
export const SpecialPropsLessComponents = {
    ModifiedValue,
    List,
    Temperature,
    Boolean,
    ChipCheckMark,
    LoggerAge,
    LoggerAgeFromDate,
    StatusAge,
    Link,
    Checkbox,
    ContainerStatusEnriched,
    AdditionalFeesStatusEnriched,
    LaneFeesStatusEnriched,
    TemperatureCelsius,
    LaneDateEnriched,
    LaneStatus,
    ContainerTemp,
    TemperatureAge,
    ContainerTempRange,
    ContainerSelectionUntil,
    Age,
    ContainerForPooling,
    StepProgress,
    NotificationType,
    LastSentOn,
    Recipients,
    SendTimeCell,
    CloudIconBoolean,
    TransportMode,
    PoolingRequestedDelivery,
    PoolingEstimatedPickup,
    EstimatedDeliveryDeparture,
    RequestedDeliveryAddInfo,
    DownloadDocument,
    QuotePdfDelete,
    QuotePdfDescriptionEdit,
    LoggerDocumentDelete,
    RemoveComment,
    AmountOfTime,
}

type SpecialPropsLessComponentConfig = {
    componentName?: keyof typeof SpecialPropsLessComponents,
}

export const ComponentTableCollection = {
    ...SpecialPropsLessComponents,
    ...EnumComponents,
    ...LabelsComponents,
    ...MultipleEnumComponents,
    WorkingDaysLeftAlert,
    SkyNetTableLink,
    TableMultipleValues,
}

/* Discriminated union for all possible configs for table cell components */
export type AllCellComponentsSpecialProps = SpecialPropsLessComponentConfig
    | EnumComponentConfig
    | LabelsComponentConfig
    | MultipleEnumComponentConfig
    | WorkingDaysLeftAlertConfig
    | SkyNetTableLinkConfig
    | MultipleValuesComponentsConfig

type OmitComponentName<TypeWithComponentNameProp> = Omit<TypeWithComponentNameProp, 'componentName'>

type SharedConfigProps = OmitComponentName<EnumComponentConfig>
    & OmitComponentName<LabelsComponentConfig>
    & OmitComponentName<MultipleEnumComponentConfig>
    & OmitComponentName<WorkingDaysLeftAlertConfig>
    & OmitComponentName<SkyNetTableLinkConfig>
    & OmitComponentName<MultipleValuesComponentsConfig>

/* It's properties from configuration that we want to share by redux */
export type SharedConfigPropNames = keyof SharedConfigProps
