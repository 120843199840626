import React from 'react'
import noop from 'lodash/noop'
import Card from 'app/shared-components/Card'
import {
    RichTableColumns,
} from 'app/types/richtable.types'
import CollapsableAddRemovePanel from 'app/shared-components/CollapsableAddRemovePanel'
import RichTable from 'app/shared-components/RichTableReduxWrapper'

import useStyles from './RichTableWithAddIcon.style'

export default function RichTableWithAddIcon({
    title,
    onOpenForm,
    name,
    configName,
    createEnabled,
    loadData,
    onParamsChange,
    columns,
    onRowClick,
}: Readonly<{
    title: string,
    onOpenForm: () => void,
    name: string,
    configName: string,
    createEnabled: boolean,
    loadData: () => Promise<any>,
    onParamsChange: (n: any) => void,
    columns: RichTableColumns<any>[],
    onRowClick: (a: Record<string, any>) => void,
}>) {
    const {
        classes,
    } = useStyles()

    return (
        <Card
            fullHeight
            contentClass={classes.cardContent}
        >
            <CollapsableAddRemovePanel
                title={title}
                onOpenForm={onOpenForm}
                onCloseForm={noop}
                formOpened={false}
                disabled={!createEnabled}
            />
            <RichTable
                configName={configName}
                name={name}
                load={loadData}
                onParamsChange={onParamsChange}
                columns={columns}
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
                onRowClick={onRowClick}
            />
        </Card>
    )
}
