import React from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import useGetAccount from '../../hooks/useGetAccount'
import BillingCompaniesByAccountCompany from './BillingCompaniesByAccountCompany'

export default function BillionCompaniesByAccountCompanyWrapper({
    id,
}: Readonly<{id: number}>) {
    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
    } = useGetAccount(id)

    return (
        <StatusHandler
            isSuccess={isDataReady && Boolean(data?.company?.id)}
            isError={isError}
            error={error}
            isFetching={isFetching}
        >
            <BillingCompaniesByAccountCompany id={data?.company?.id} />
        </StatusHandler>

    )
}
