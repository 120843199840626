import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    CommentType,
} from 'app/Apps/OrderManagement/CommentsTable/commentsTable.types'

import requestConfig from '../longTermLeaseComments.request'

const useGetLongTermLeaseComments = (longTermLeaseId: number) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<CommentType>({
        key: RequestKeys.getLongTermLeaseComments,
        params: {
            longTermLeaseId,
        },
        config: requestConfig.filterRequestAll,
        keepPreviousData: true,
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    }
}

export default useGetLongTermLeaseComments
