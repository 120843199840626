import React, {
    useState, useCallback,
} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    useSharedContext,
} from 'app/shared-components/SharedContext'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import {
    useAuth,
} from 'app/Auth'

type RowProps = {
    createdBy: string,
    comment: string,
    id: number
}
const RemoveComment = ({
    rowProps,
}: {
    rowProps: RowProps,
}) => {
    const userInfo = useAuth()
    const [
        confirmation,
        setConfirmation,
    ] = useState<boolean>(false)

    const {
        createdBy, comment, id,
    } = rowProps

    const {
        deleteComment,
    } = useSharedContext()

    const closeConfirmation = useCallback((positive) => {
        if (positive) {
            deleteComment(id)
        }
        setConfirmation(false)
    }, [
        id,
        deleteComment,
    ])

    const onRemoveClick = useCallback(() => {
        setConfirmation(true)
    }, [])

    return userInfo?.user?.email === createdBy ? (
        <>
            <DeleteIcon
                data-testid="DeleteIcon"
                onClick={onRemoveClick}
            />
            <ConfirmationDialog
                isPositiveAlert
                positiveLabel="Delete Comment"
                negativeLabel="Cancel"
                open={!!confirmation}
                handleClose={closeConfirmation}
                dialogTitle="Delete Comment"
                dialogContent={`Are you sure you would like to permanently delete a comment "${comment}"?`}
            />
        </>
    ) : null
}

export default RemoveComment
