import React from 'react'

import {
    useLocation, useSearchParams,
} from 'react-router-dom'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'
import BillingCompanyForm from '../BillingCompanyForm'
import {
    BillingCompany,
} from '../BillingCompanyForm.types'
import requests from '../BillingCompanies.request'

const BillingCompanyCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const {
        search,
        state,
    } = useLocation()

    const [searchParams] = useSearchParams(search)

    return (
        <BillingCompanyForm
            data={{
                company: {
                    id: searchParams?.get('companyId') || state?.company?.id,
                } as unknown as Company,
            } as BillingCompany}
            onSuccess={onSuccess}
            request={requests.create()}
        />
    )
}

export default BillingCompanyCreate
