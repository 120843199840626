import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tooltipWrapper: {
            width: 600,
            height: 100,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            zIndex: 3000,
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        item: {
            flexGrow: 1,
            flexBasis: 0,
        },
        label: {
            color: theme.palette.secondary[600],
            fontSize: 12,
            paddingBottom: theme.spacing(1),
        },
        valueWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        marker: {
            width: 16,
            height: 16,
            borderRadius: 2,
        },
        processed: {
            background: '#8BD1E8',
        },
        inProcess: {
            background: '#EDAE49',
        },
        preconditioned: {
            background: '#4790A8',
        },
        toBeProcessed: {
            background: '#F2CA8B',
        },
        inPreconditioning: {
            background: '#57B1CF',
        },
        error: {
            background: '#D44848',
        },
        value: {
            paddingLeft: theme.spacing(1),
        },
    }
})

export default useStyles
